<template>
	<div class="MeterialBox" v-loading='loading'>
		<div class="MeterialTop">
			<img v-if="InitInfo.ImgUrl" :src="imgUrl+InitInfo.ImgUrl+'@!cut64'">
			<div class="right">
				<div>{{InitInfo.Name}}</div>
				<div style="color:#909399;margin-top: 10px;">{{InitInfo.ProductNo}}</div>
			</div>
		</div>
		<div class="MeterialContain">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" placeholder="素材名称" style="width:250px;margin-right: 10px;" clearable
					 @keyup.enter.native="goodsFilter"></el-input>
					<el-button type='primary' @click='goodsFilter'>查询</el-button>
					<el-button type='primary' @click='addMeterail({Id:0})'>添加</el-button>
				</div>

			</div>

			<!-- 表格 -->
			<div class="table-container">
				<el-table :data="MeterialData">
					<el-table-column prop="MaterialName" label="素材名称">
						<template slot-scope="scope">
							<div style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{scope.row.MaterialName}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="MaterialTypeStr" label="素材类型"></el-table-column>
					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column label="操作" width="140">
						<template slot-scope="scope">
							<el-button type="text" @click="addMeterail(scope.row)">编辑</el-button>
							<span style="color:#f00;margin-left:10px;cursor: pointer;" @click="deleteChose(scope.row)">删除</span>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import config from '@/config/index'
	import {
		productMateriaList,
		productMateriaDelete,
		productMateriaInit
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				InitInfo:{},
				ProductId: 0,
				searchKey: '',
				loading: false,
				MeterialData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
			}
		},
		created() {
			this.ProductId = this.$route.query.ProductId ? this.$route.query.ProductId : 0

			console.log(this.ProductId, '商品id')
			this.getMeterialList()
			this.getMeterialInit()
		},
		methods: {
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getMeterialList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getMeterialList();
			},
			//添加素材
			addMeterail(item) {
				this.$router.push({
					path: '/goods/AddMeterialist',
					query: {
						MeteriaId: item.Id ? item.Id : 0,
						ProductId:this.ProductId?this.ProductId:0
					}
				});
			},
			goodsFilter() {
				this.currentPage = 1;
				this.getMeterialList();
			},
			async getMeterialInit(){
				try{
					this.loading = true
					let data ={
						ProductId : this.ProductId
					}
					let result = await productMateriaInit(data)
					if(result.IsSuccess){
						this.InitInfo = result.Result
					}
				}catch(err){
					
				}finally{
					
				}
			},
			async getMeterialList() {
				try {
					this.loading = true
					let data = {
						KeyWord: this.searchKey,
						ProductId: this.ProductId,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await productMateriaList(data)
					if (result.IsSuccess) {
						this.Total = result.Result.Total;
						this.MeterialData = result.Result.Results || [];
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			deleteChose(record) {
				this.$confirm('是否确认删除素材?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {

					let result = await productMateriaDelete({
						Id: record.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.getMeterialList();
					}, 500)

				})


			},
		}
	}
</script>

<style lang="less">
	.MeterialBox {
		font-size: 15px;

		.MeterialTop {
			background: #f0ecec;
			display: flex;
			align-items: center;
			padding: 10px;

			img {
				width: 64px;
				height: 64px;
				margin-right: 10px;
			}
		}

		.MeterialContain {
			background: #fff;
			padding: 10px;
		}
	}
</style>
